import store from "../store";
import en from "@/locales/en.json";
// import ua from "@/locales/ua.json";

const locales = {
  en: en,
  // ua: ua,
};

export default function localizeFilter(key) {
  let locale = "en";
  locale = store.getters.user.locale || "en";
  return locales[locale][key];
}
