<template>
  <div class="bg-wrapper">
    <div class="container">
      <div class="contact-us__inner contact-us">
        <div class="contact-us-title default-title">
          {{ "contact_us_title" | localize }}
        </div>
        <div class="contact-us-desc default-title">
          {{ "contact_us_desc" | localize }}
        </div>
        <div class="contact-us__list">
          <div class="contact_us__list-card">
            <div class="contact_us__list-card-img">
              <img src="../assets/general-1.jpg" alt="" />
            </div>
            <div class="contact_us__list-card__text">
              <div class="contact_us_list-card__text-title">
                MG Glenn Curtis (RET)
              </div>
              <!-- <div class="contact_us_list-card__text-phone">
                <a href="tel:+38(098)38 123 739">Phone: +38(098)38 123 739</a>
              </div> -->
              <div class="contact_us_list-card__text-email">
                <a href="mailto:g.curtis@ugtwest.com">
                  Email: g.curtis@ugtwest.com
                </a>
              </div>
            </div>
          </div>
          <div class="contact_us__list-card">
            <div class="contact_us__list-card-img">
              <img src="../assets/general-2.jpg" alt="" />
            </div>
            <div class="contact_us__list-card__text">
              <div class="contact_us_list-card__text-title">
                MG David Baldwin (RET)
              </div>
              <!-- <div class="contact_us_list-card__text-phone">
                <a href="tel:+38(098)38 123 739">Phone: +38(098)38 123 739</a>
              </div> -->
              <div class="contact_us_list-card__text-email">
                <a href="mailto:d.baldwin@ugtwest.com">
                  Email: d.baldwin@ugtwest.com
                </a>
              </div>
            </div>
          </div>
          <div class="contact_us__list-card">
            <div class="contact_us__list-card-img">
              <img src="../assets/general-3.jpg" alt="" />
            </div>
            <div class="contact_us__list-card__text">
              <div class="contact_us_list-card__text-title">
                Yaroslav Dotsenko
              </div>
              <!-- <div class="contact_us_list-card__text-phone">
                <a href="tel:+38(098)38 123 739">Phone: +38(098)38 123 739</a>
              </div> -->
              <div class="contact_us_list-card__text-email">
                <a href="mailto:Y.dotsenko@ugtwest.com">
                  Email: Y.dotsenko@ugtwest.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-us__banner">
          <div class="contact-us__banner-title">
            {{ "contact_us_banner_title" | localize }}
          </div>
          <div class="contact-us__banner-desc">
            {{ "contact_us_banner_desc" | localize }}
          </div>
          <div class="d-flex align-center justify-center">
            <button
              class="default-btn contact-us-btn d-flex align-center justify-center"
              @click="dialogVisible = true"
            >
              <span>
                {{ "contact_us" | localize }}
              </span>
              <v-icon class="ml-3" color="#fafafa" size="24"
                >mdi-arrow-right</v-icon
              >
            </button>
          </div>
        </div>
      </div>
      <dialogComponent
        :visible="dialogVisible"
        @close="dialogVisible = false"
        v-if="dialogVisible"
      />
    </div>
  </div>
</template>

<script>
import dialogComponent from "@/components/dialogs/dialogComponent.vue";
export default {
  data: () => ({
    dialogVisible: false,
  }),
  components: {
    dialogComponent,
  },
};
</script>
<style scoped lang="scss">
.contact-us {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.contact-us-title {
  margin-bottom: 12px;
}
.contact-us-desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 22.08px;
  text-align: center;
  color: #616161;
  margin-bottom: 40px;
}
.contact-us__inner {
  padding: 70px 120px;
}
.contact-us__list {
  margin-bottom: 70px;
}
.contact_us__list-card {
  display: flex;
  gap: 20px;
  padding: 16px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 36px;
}
.contact_us_list-card__text-title {
  color: var(--black, #1b1b1b);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}
.contact_us_list-card__text-phone {
  margin-bottom: 30px;
  padding: 8px 14px;
  border-radius: 4px;
  border-bottom: 1px solid rgba(22, 71, 28, 0.1);
}
.contact_us_list-card__text-phone a {
  color: var(--black, #1b1b1b);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-decoration: none;
}
.contact_us_list-card__text-email {
  margin-bottom: 30px;
  padding: 8px 14px;
  border-radius: 4px;
  border-bottom: 1px solid rgba(22, 71, 28, 0.1);
}
.contact_us_list-card__text-email a {
  color: var(--black, #1b1b1b);
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-decoration: none;
}
.contact-us-btn {
  max-width: 380px;
  width: 100%;
  padding: 16px;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
}

.contact-us__banner {
  padding: 90px 130px;
  background-image: url("../assets/contact-us-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}
.contact-us__banner-title {
  text-align: center;
  font-size: 40px;
  color: #fafafa;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}
.contact-us__banner-desc {
  color: #aaa;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
}
.contact_us__list-card-img img {
  object-fit: contain;
  width: 300px;
  max-height: 300px;
}
@media (max-width: 1200px) {
  .contact_us__list-card-img img {
    object-fit: contain;
    max-width: 300px;
  }
}
@media (max-width: 970px) {
  .contact-us__inner {
    padding: 70px 50px;
  }
  .contact_us__list-card {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .contact_us__list-card-img img,
  .contact_us__list-card-im {
    object-fit: contain;
    max-width: 100%;
    display: block;
  }
}
@media (max-width: 700px) {
  .contact-us__banner {
    padding: 60px;
  }
}
@media (max-width: 550px) {
  .contact-us__inner {
    padding: 30px 20px;
  }
  .contact_us_list-card__text-title {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .contact_us_list-card__text-email a {
    font-size: 14px;
  }
  .contact-us__banner-title {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .contact-us__banner-desc {
    font-size: 18px;
  }
  .contact-us-btn {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .contact-us__banner {
    padding: 25px;
  }
}
</style>
