<template>
  <v-dialog v-model="visibility" content-class="navigation-dialog">
    <v-card class="navigation-card">
      <div class="navigation-header">
        <div class="navigation-logo">
          <a href="#"><img style="width: 200px" src="../../assets/black-logo.png" alt="logo" /></a>
        </div>
        <div class="navigation-close">
          <v-btn icon @click="visibility = false" x-large
            ><v-icon color="black">mdi-close</v-icon></v-btn
          >
        </div>
      </div>
      <div class="navigation__list">
        <div class="navigation__list-item" @click="scrollToId('benefits')">
          {{ "our_advantages" | localize }}
        </div>
        <div class="navigation__list-item" @click="scrollToId('products')">
          {{ "our_products" | localize }}
        </div>
        <div class="navigation__list-item" @click="scrollToId('aboutUs')">
          {{ "about_us" | localize }}
        </div>
        <div
          class="navigation__list-item"
          @click="scrollToId('productsGallery')"
        >
          {{ "gallery" | localize }}
        </div>
      </div>
      <!-- <div class="navigation-phone">
        <a href="tel:+380503308802">+38 (050) 330-88-02</a>
      </div> -->
      <div class="navigation-phone">
        <a href="mailto:info@ugtwest.com">info@ugtwest.com</a>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    async scrollToId(id) {
      if (this.$route.path != "/photos") {
        const element = document.getElementById(id);
        const scrollOffset = 120;
        const targetPosition = element.offsetTop;
        window.scrollTo({
          top: targetPosition - scrollOffset,
        });
        this.visibility = false;
      } else {
        await this.$router.push("/");
        const element = document.getElementById(id);
        const scrollOffset = 120;
        const targetPosition = element.offsetTop;
        window.scrollTo({
          top: targetPosition - scrollOffset,
        });
        this.visibility = false;
      }
    },
    ...mapActions(["updateInfo"]),
    changeUserLocale(locale) {
      this.updateInfo({
        locale: locale,
      });
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      },
    },
    ...mapGetters(["user"]),
  },
};
</script>
<style>
.navigation-dialog {
  transition: none !important;
  position: absolute;
  height: 100dvh;
  max-height: 100dvh !important;
}
.navigation-card {
  height: 100dvh;
}
.navigation-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: 110px;
  align-items: center;
  margin-bottom: 100px;
}
.navigation-close {
  display: flex;
  align-items: center;
}
.navigation__list {
  margin-bottom: 40px;
}
.navigation__list-item {
  color: #000;
  font-family: "MacPaw Fixel";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  cursor: pointer;
}
.navigation__list-item + .navigation__list-item {
  margin-top: 40px;
}
.navigation__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 30px;
}
.navigation__icons-icon + .navigation__icons-icon {
  margin-left: 53px;
}
.navigation-phone {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.navigation-phone a {
  color: #000 !important;
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
</style>

<style scoped>
.activeLanguage {
  color: #33ad47;
}
.inactiveLanguage {
  color: #33ad47;
  opacity: 0.5;
}
</style>
